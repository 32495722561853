import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { navigate } from '../../../../store/pages';
import { openDialog } from '../../../../store/dialogs';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import LoggerProfileChart from '../../../../components/charts/preview/LoggerProfileChart';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';
import LoggerInactiveCard from '../../../../components/LoggerInactiveCard';

function LoggerCard({ logger }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { alarms } = useLoggerDevices(logger.logger_id);

  const handleLoggerClick = () => {
    dispatch(
      navigate({
        page: 'logger',
        id: logger.logger_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'logger',
        mode: 'edit',
        id: logger.logger_id,
      })
    );
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Box
      sx={{
        px: 1,
        pt: 1,
        pb: 0.5,
        backgroundColor: 'background.paper',
        boxShadow: theme.shadows[4],
        borderRadius: 1,
        width: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AlarmPreviewLink
          alarms={activeAlarms}
          deviceName={logger.name}
          handleClick={() => handleLoggerClick()}
        />
        <IconButton onClick={() => handleIconClick()}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      </Box>
      {logger.active ? (
        <LoggerProfileChart logger={logger} />
      ) : (
        <LoggerInactiveCard deviceName={logger.name} />
      )}
    </Box>
  );
}

LoggerCard.propTypes = {
  logger: PropTypes.object.isRequired,
};

export default LoggerCard;
