import React from 'react';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import _package from '../../../../package.json';
import ROUTES from '../../../constants/routes';
import NavTree from './NavTree';

function DrawerContent() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
      <Box
        component='img'
        alt='LightLevel By Veregy'
        src='/images/LightLevel_by_Veregy.svg'
        title='LightLevel By Veregy'
        sx={{ px: 2, py: 1 }}
      />
      <Divider />
      <NavTree />
      <Divider />
      <div style={{ flex: 1 }} />
      <Divider />

      <List dense={!isMobile} style={{ margin: '0px', padding: '0px' }}>
        <ListItemButton
          divider={true}
          onClick={() => dispatch(push(ROUTES.AUTH.PROFILE))}>
          <ListItemIcon style={{ minWidth: '34px' }}>
            <FontAwesomeIcon icon={['fal', 'user-circle']} size='lg' />
          </ListItemIcon>
          <ListItemText
            primary='Profile'
            primaryTypographyProps={{ color: 'text.secondary' }}
          />
        </ListItemButton>
        <ListItem divider={true}>
          <ListItemText
            secondary='Terms'
            onClick={() => dispatch(push(ROUTES.AUTH.TERMS))}
            sx={{ cursor: 'pointer' }}
            secondaryTypographyProps={{ align: 'center' }}
          />
          <ListItemText
            secondary='Privacy'
            onClick={() => dispatch(push(ROUTES.AUTH.PRIVACY))}
            sx={{ cursor: 'pointer' }}
            secondaryTypographyProps={{ align: 'center' }}
          />
          <ListItemText
            secondary={'LightLevel v' + _package.version}
            secondaryTypographyProps={{ align: 'center' }}
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default DrawerContent;
