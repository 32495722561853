import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import filter from 'lodash/filter';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ROLES from '../../../../constants/roles';
import { setPage } from '../../../../store/pages';
import CountGrid from '../../../../components/Grid/CountGrid';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import Map from '../../../../components/Map';
import SitesGrid from './SitesGrid';
import ViewStack from '../../../../components/ViewStack';
import useOrganizationDevices from '../../../../store/hooks/useOrganizationDevices';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useOrganization from '../../../../store/hooks/useOrganization';

const PAGE = 'organization';
const SITES = 'sites';
const MAP = 'map';
const ALARMS = 'alarms';
const VIEWS = [SITES, MAP, ALARMS];

function OrganizationDashboard() {
  const dispatch = useDispatch();
  const { id, view, expectationType } = useSelector(
    (state) => state.pages.organization
  );
  const { organization } = useOrganization(id);
  const { sites, loggers, meters, inverters, sensors, alarms, loading } =
    useOrganizationDevices(id);

  const isOrgAdmin = useVerifyOrgRole(id, ROLES.ADMIN.value);
  const [selectedView, setSelectedView] = useState(SITES);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(SITES);
    }
  }, [view]);

  const handleSetExpectationType = (type) => {
    const _type = type || 'modeled';
    dispatch(setPage({ page: PAGE, expectationType: _type }));
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      mt='0 !important'
      px={1}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} md={4}>
        <ResourceCount loading={loading}>
          <CountGrid number={sites.length} name='Sites' />
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid item xs={12} md={4}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProductionIndex
          sites={sites}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      {selectedView === SITES && (
        <SitesGrid
          organization={organization}
          sites={sites}
          expectationType={expectationType}
          loading={loading}
        />
      )}
      {selectedView === ALARMS && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={organization} alarms={alarms} />
        </Grid>
      )}
      {selectedView === MAP && (
        <Grid item xs={12}>
          <Map
            center={organization}
            locations={sites}
            styles={{ height: '58vh', width: '100%' }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default OrganizationDashboard;
