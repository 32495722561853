import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Grid from '@mui/material/Grid';

import { navigate } from '../../../../store/pages';
import SiteCard from './SiteCard';
import CustomLink from '../../../../components/CustomLink';

function SitesGrid(props) {
  const { organization, sites, expectationType, loading } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'organization',
        id: organization.org_id,
        tab: 'config',
        view: 'sites',
      })
    );
  };

  if (!loading && sites.length === 0) {
    return (
      <Grid key='no-sites-card' item xs={12}>
        <CustomLink handleClick={handleClick}>Configure Sites</CustomLink>
      </Grid>
    );
  }

  return map(sortBy(sites, 'name'), (site) => {
    return (
      <Grid
        key={site.site_id}
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <SiteCard siteId={site.site_id} expectationType={expectationType} />
      </Grid>
    );
  });
}

SitesGrid.propTypes = {
  organization: PropTypes.object,
  sites: PropTypes.array,
  expectationType: PropTypes.string,
  loading: PropTypes.bool,
};

export default SitesGrid;
