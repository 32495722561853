import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { unixTimestampToString } from '../../../../helpers/date';
import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

const renderValue = (resource) => {
  if (resource.dataKey === 'radiation') {
    return (
      numeral(resource.value.toPrecision(4)).format('0,0.[000]') + ' W/m^2'
    );
  }
  if (resource.dataKey === 'temp') {
    return numeral(resource.value.toPrecision(4)).format('0,0.[0]') + ' °C';
  }
  return displaykW(resource.value);
};

function Tooltip({ active, payload, label, timezone }) {
  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={unixTimestampToString(Number(label), timezone?.offset)}
      getName={(resource) => resource?.name}
      getValue={renderValue}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
