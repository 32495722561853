import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlarms } from '../alarms';

const useAlarms = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);
  const { loaderOpen } = useSelector((state) => state.pages);

  useEffect(() => {
    const refreshAlarmsCheck = () => {
      dispatch(getAlarms());
    };

    refreshAlarmsCheck();
    const interval = setInterval(async () => {
      refreshAlarmsCheck();
    }, 1800000); // 30 min
    return () => clearInterval(interval);
  }, [loaderOpen, dispatch, user]);
};

export default useAlarms;
