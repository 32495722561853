import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';

import ROLES from '../../constants/roles';
import { ALARM_LEVELS } from '../table/providers';
import useSite from '../../store/hooks/useSite';
import useMeter from '../../store/hooks/useMeter';
import useTrigger from '../../store/hooks/useTrigger';
import useAlarm from '../../store/hooks/useAlarm';
import useDevice from '../../store/hooks/useDevice';
import {
  closeDialog,
  openDialog,
  openEmailedUsersDialog,
} from '../../store/dialogs';
import { navigate } from '../../store/pages';
import {
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
} from '../../store/alarms/_alarms';
import useVerifyOrgRole from '../../store/hooks/useVerifyOrgRole';
import { TextFieldListItem } from '../List/TextFieldListItem';
import BaseDialog from './BaseDialog';

function AlarmDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.dialogs.alarm);
  const memberships = useSelector((state) => state.memberships.data);

  const alarm = useAlarm(id);
  const device = useDevice(alarm.device_id);
  const site = useSite(device?.site_id);
  const meter = useMeter(device?.meter_id);
  const trigger = useTrigger(alarm.trigger_id);
  const isOrgAdmin = useVerifyOrgRole(alarm?.org_id, ROLES.ADMIN.value);
  const isOrgEditor = useVerifyOrgRole(alarm?.org_id, ROLES.EDITOR.value);

  const handleClose = () => {
    dispatch(closeDialog('alarm'));
  };

  const handleCloseAlarm = () => {
    dispatch(closeAlarm(alarm));
    handleClose();
  };

  const handleAckAlarm = () => {
    dispatch(acknowledgeAlarm(alarm));
    handleClose();
  };

  const handleDeleteAlarm = () => {
    dispatch(deleteAlarm(alarm));
    handleClose();
  };

  const handleLinkClick = (page, id) => {
    dispatch(
      navigate({
        page,
        id,
      })
    );
    handleClose();
  };

  const handleTriggerClick = () => {
    dispatch(
      openDialog({
        type: 'trigger',
        id: trigger.trigger_id,
      })
    );
  };

  const handleShowEmailedUsers = () => {
    dispatch(openEmailedUsersDialog(alarm));
  };

  const renderDeviceLink = () => {
    const separator = '/';
    const devices = compact([
      site.site_id ? site : null,
      meter?.meter_id ? meter : null,
      device.type_ && !includes(['meter', 'site'], device.type_)
        ? device
        : null,
    ]);

    // insert separator in between devices
    const links = devices.reduce((acc, curr, idx) => {
      acc.push(curr);
      if (idx < devices.length - 1) {
        acc.push(separator);
      }
      return acc;
    }, []);

    return (
      <>
        {links.map((element, idx) => {
          if (element === separator) {
            return <span key={idx}> {separator} </span>;
          }

          const id = get(element, `${element.type_}_id`);
          return (
            <Link
              key={id}
              underline='hover'
              onClick={() => handleLinkClick(element.type_, id)}>
              {element.name}
            </Link>
          );
        })}
      </>
    );
  };

  const renderCreatedAt = (value) => {
    const offset = value.slice(-6);
    const datetime = value.substring(0, 19);
    return dayjs(datetime + offset).format('MM/DD/YYYY HH:mm z');
  };

  const findUserName = (userId) => {
    const membership = find(memberships, { user_id: userId });
    return get(membership, 'name', userId);
  };

  const alarmStatus = get(alarm, 'status', false);
  const titleOptions = [
    isOrgAdmin && (
      <MenuItem key='emailed-users-button' onClick={handleShowEmailedUsers}>
        Emailed Users
      </MenuItem>
    ),
    !alarm?.acknowledged_by && (
      <MenuItem key='acknowledge-button' onClick={handleAckAlarm}>
        Acknowledge
      </MenuItem>
    ),
    (isOrgAdmin || isOrgEditor) && alarmStatus && (
      <MenuItem key='close-button' onClick={handleCloseAlarm}>
        Close
      </MenuItem>
    ),
    isOrgAdmin && !alarmStatus && (
      <MenuItem key='delete-button' onClick={handleDeleteAlarm}>
        Delete
      </MenuItem>
    ),
  ].filter(Boolean);

  return (
    <BaseDialog
      id={id}
      title='Alarm'
      handleClose={handleClose}
      titleOptions={titleOptions}>
      <List disablePadding>
        <ListItem disableGutters>
          <FormControl variant='standard'>
            <InputLabel htmlFor='device-field' shrink>
              Device
            </InputLabel>
            <div id='device-field' style={{ marginTop: theme.spacing(3) }}>
              {renderDeviceLink()}
            </div>
          </FormControl>
        </ListItem>

        <TextFieldListItem
          id='message'
          label='Message'
          value={alarm.message}
          multiline
          InputProps={{ readOnly: true, disableUnderline: true }}
        />

        <ListItem disableGutters>
          <FormControl variant='standard'>
            <InputLabel htmlFor='trigger-field' shrink>
              Trigger
            </InputLabel>
            <div id='trigger-field' style={{ marginTop: theme.spacing(3) }}>
              <Link underline='hover' onClick={handleTriggerClick}>
                {startCase(get(trigger, 'trigger_id', ''))}
              </Link>
            </div>
          </FormControl>
        </ListItem>

        <TextFieldListItem
          id='status'
          label='Status'
          value={alarm.status ? 'active' : 'inactive'}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
        <TextFieldListItem
          id='level'
          label='Severity'
          value={ALARM_LEVELS[alarm.level]}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position='start'>
                <FontAwesomeIcon icon={['fal', `signal-${alarm.level + 1}`]} />
              </InputAdornment>
            ),
          }}
        />

        <TextFieldListItem
          id='created_on'
          label='Date Triggered'
          value={renderCreatedAt(alarm.created_on)}
          InputProps={{ readOnly: true, disableUnderline: true }}
        />
        {get(alarm, 'acknowledged_on') && (
          <TextFieldListItem
            id='acknowledged_on'
            label='Date Acknowledged'
            value={renderCreatedAt(alarm.acknowledged_on)}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        )}
        {get(alarm, 'acknowledged_by') && (
          <TextFieldListItem
            id='acknowledged_by'
            label='Acknowledged By'
            value={findUserName(alarm.acknowledged_by)}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        )}
      </List>
    </BaseDialog>
  );
}

export default AlarmDialog;
