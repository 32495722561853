import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { sensors as initialState } from '../initialState';

import { getSensors, putSensor, refreshSensors } from './_sensors';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    setSensors: (state, { payload }) => ({ ...state, data: payload }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getSensors, putSensor, refreshSensors]);
  },
});

// Extract each action creator by name
const { setSensors } = actions;

// Export the reducer, either as a default or named export
export { getSensors, putSensor, setSensors, refreshSensors };
export default reducer;
