import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';
import WebAPIClient, { errorResponseToastr } from '../../../../api';
import ProductionProfileChart from '../../../../components/charts/analysis/ProductionProfileChart';
import SelectMeters from '../../../../components/charts/selectors/SelectMeters';
import SelectInverters from '../../../../components/charts/selectors/SelectInverters';
import SelectRange, {
  RANGES,
} from '../../../../components/charts/selectors/SelectRange';
import SelectTimezone, {
  TIMEZONES,
} from '../../../../components/charts/selectors/SelectTimezone';
import SelectInterval, {
  INTERVALS,
} from '../../../../components/charts/selectors/SelectInterval';
import SelectUnit, {
  UNITS,
} from '../../../../components/charts/selectors/SelectUnits';
import GenerateCSVButton from '../../../../components/charts/buttons/GenerateCSVButton';
import ChartHeader from '../ChartHeader';
import { calculateTotals, compileChartData, prepareCsvData } from './helpers';

function ProductionProfileChartContainer(props) {
  const { defaultTimezone, meters, inverters } = props;

  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totals, setTotals] = useState({ meter: 0, inverter: 0 });
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [selectedInverters, setSelectedInverters] = useState([]);
  const [range, setRange] = useState(RANGES[1]);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [unit, setUnit] = useState(UNITS[1]);
  const [interval, setInterval] = useState(INTERVALS[0]);

  useEffect(() => {
    if (meters.length <= 5) {
      setSelectedMeters(meters);
    } else {
      const _meters = sortBy(meters, 'name');
      setSelectedMeters(_meters.slice(0, 5));
    }
  }, [meters]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // update range, after timezone updates
  useEffect(() => {
    if (timezone?.offset) {
      setRange((r) => {
        if (r.key === 6) {
          return {
            ...r,
            start: r.func(r.start, 'start', timezone.offset),
            end: r.func(r.end, 'end', timezone.offset),
            offset: timezone.offset,
          };
        } else {
          return { ...r, ...r.func(timezone.offset), offset: timezone.offset };
        }
      });
    }
  }, [timezone.offset]);

  // compile chartData after rawDat updates
  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData, range, unit, interval));
    }
  }, [rawData, range, unit, interval, loading]);

  // set default chart data
  useEffect(() => {
    if (isEmpty(chartData) && range.start && range.end) {
      const start = range.start.unix();
      const end = range.end.unix();
      let _data = map(selectedMeters, (meter) => {
        return {
          device_id: meter.meter_id,
          records: filter(meter?.timeseries, (record) => {
            return record.timestamp >= start && record.timestamp <= end;
          }),
        };
      });
      if (_data.length > 0) {
        setRawData(_data);
      }
    }
  }, [selectedMeters, chartData, range]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      setTotals(calculateTotals(chartData, unit));
    }
  }, [chartData, unit]);

  const handleIntervalChange = (value) => {
    if (value.minutes === 15) {
      setUnit(UNITS[1]);
    } else {
      setUnit(UNITS[4]);
    }
    setInterval(value);
  };

  const fetchRawData = (range) => {
    if (selectedMeters.length === 0) {
      toastr.warning('Select at least one meter');
      setChartData([]);
      return;
    }

    setLoading(true);
    Promise.all(
      map(concat(selectedMeters, selectedInverters), async (device) => {
        const deviceId = get(device, `${device.type_}_id`);
        return {
          device_id: deviceId,
          records: await new WebAPIClient().GET(
            `/resource/timestream/${
              device.org_id
            }/${range.start.valueOf()}/${range.end.valueOf()}`,
            { deviceId }
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectRange = (newRange, fetchData) => {
    setRange(newRange);
    if (fetchData) {
      fetchRawData(newRange);
    }
  };

  return (
    <>
      <ChartHeader>
        <SelectMeters
          allMeters={meters}
          selectedMeters={selectedMeters}
          setSelectedMeters={setSelectedMeters}
        />
        <SelectInverters
          allInverters={inverters}
          selectedInverters={selectedInverters}
          setSelectedInverters={setSelectedInverters}
        />

        <SelectRange
          range={range}
          setRange={handleSelectRange}
          timezone={timezone}
        />

        <SelectTimezone
          selectedTimezone={timezone}
          setSelectedTimezone={setTimezone}
        />

        <SelectInterval
          selectedInterval={interval}
          setSelectedInterval={handleIntervalChange}
        />

        <SelectUnit
          selectedUnit={unit}
          setSelectedUnit={setUnit}
          units={UNITS}
        />

        <Tooltip title='fetch data' placement='top'>
          <IconButton onClick={() => fetchRawData(range)}>
            <FontAwesomeIcon icon={['fal', 'cloud-download']} />
          </IconButton>
        </Tooltip>

        <Tooltip title='download CSV' placement='top'>
          <span>
            <GenerateCSVButton
              generateCsvString={() =>
                prepareCsvData(
                  chartData,
                  selectedMeters,
                  selectedInverters,
                  unit
                )
              }
              filename={`Production Profile (${dayjs().format(
                'ddd MMM DD YYYY'
              )})`}
            />
          </span>
        </Tooltip>
      </ChartHeader>

      <Grid item xs={12}>
        <Card raised sx={{ px: 1, py: 0.5 }}>
          <ProductionProfileChart
            chartData={chartData}
            meters={selectedMeters}
            inverters={selectedInverters}
            range={range}
            timezone={timezone}
            unit={unit}
            loading={loading}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card raised sx={{ p: 1 }}>
          <Stack direction='row'>
            {selectedMeters.length > 0 && (
              <Typography sx={{ mr: 2 }} color='text.secondary'>
                Meter Production:
              </Typography>
            )}
            {selectedMeters.length > 0 && (
              <Typography sx={{ mr: 15 }}>
                {displaykW(totals.meter, true)}
              </Typography>
            )}
            {selectedInverters.length > 0 && (
              <Typography sx={{ mr: 2 }} color='text.secondary'>
                Inverter Production:
              </Typography>
            )}
            {selectedInverters.length > 0 && (
              <Typography>{displaykW(totals.inverter, true)}</Typography>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );
}

ProductionProfileChartContainer.propTypes = {
  defaultTimezone: PropTypes.object,
  meters: PropTypes.array,
  inverters: PropTypes.array,
};

export default ProductionProfileChartContainer;
