import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import dayjs from 'dayjs';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { refreshInverters } from '../inverters';

const useInverters = (id) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (id && organizations.length && sites.length && meters.length) {
      if (id.startsWith('org:')) {
        setDevice(find(organizations, { org_id: id }));
      } else if (id.startsWith('site:')) {
        setDevice(find(sites, { site_id: id }));
      } else if (id.startsWith('logger:')) {
        setDevice(find(loggers, { logger_id: id }));
      } else if (id.startsWith('meter:')) {
        setDevice(find(meters, { meter_id: id }));
      } else if (id.startsWith('inverter:')) {
        setDevice(find(inverters, { inverter_id: id }));
      }
    }
  }, [id, organizations, sites, loggers, meters, inverters]);

  useEffect(() => {
    const refreshInvertersCheck = (inverters) => {
      // console.log(`refresh inverters: ${dayjs()}`);
      dispatch(
        refreshInverters(map(inverters, (inverter) => inverter.inverter_id))
      );
    };

    let _inverters = [];
    if (device?.type_ === 'organization') {
      if (!device.is_portfolio) {
        _inverters = filter(inverters, { org_id: device.org_id });
      } else {
        _inverters = inverters;
      }
    } else if (device?.type_ === 'site') {
      let _siteMeters = filter(meters, { site_id: device.site_id });
      let meterIds = map(_siteMeters, (meter) => meter.meter_id);
      _inverters = filter(inverters, (inverter) =>
        includes(meterIds, inverter.meter_id)
      );
    } else if (device?.type_ === 'logger') {
      let _loggerMeters = filter(meters, { logger_id: device.logger_id });
      let meterIds = map(_loggerMeters, (meter) => meter.meter_id);
      _inverters = filter(inverters, (inverter) =>
        includes(meterIds, inverter.meter_id)
      );
    } else if (device?.type_ === 'meter') {
      _inverters = filter(inverters, { meter_id: device.meter_id });
    } else if (device?.type_ === 'inverter') {
      _inverters = [device];
    }

    if (_inverters.length > 0) {
      refreshInvertersCheck(_inverters);
      const interval = setInterval(async () => {
        refreshInvertersCheck(_inverters);
      }, 300000); // 5 min
      return () => clearInterval(interval);
    }
    /* eslint-disable-next-line */
  }, [device]);
};

export default useInverters;
