import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import { prepareInverterChartData } from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function InverterChart(props) {
  const { site, inverter } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const theme = useTheme();
  const [range, setRange] = useState({});
  const [chartData, setChartData] = useState([]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      let start = dayjs
        .utc()
        .startOf('day')
        .subtract(site.timezone.offset, 'hour')
        .subtract(1, 'day');
      let end = dayjs.utc().endOf('day').subtract(site.timezone.offset, 'hour');

      setRange({ start, end });
    }
  }, [site]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(inverter) && range.start && range.end) {
      setChartData(
        prepareInverterChartData(
          {
            inverter_id: inverter.inverter_id,
            records: inverter.timeseries,
          },
          range
        ),
        [inverter],
        site?.timezone?.offset
      );
    }
  }, [inverter, range, site?.timezone?.offset]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'inverter',
        id: inverter.inverter_id,
        tab: 'analysis',
      })
    );
  };

  if (isEmpty(inverter)) return null;

  const height = isMobile ? 150 : 175;
  if (chartData.length === 0)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );
  return (
    <ResponsiveContainer
      key={`chart-${inverter.inverter_id}`}
      width='100%'
      height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 5,
          right: 2,
          left: 2,
          bottom: 0,
        }}>
        <YAxis
          mirror
          domain={[
            0,
            (dataMax) =>
              dataMax > get(inverter, 'ac_size', 0)
                ? dataMax + 5
                : get(inverter, 'ac_size', 0) + 5,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' kW';
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[range.start.unix(), range.end.unix()]}
          hide
        />
        <ReferenceLine y={0} stroke={theme.palette.veregy_colors.lightgrey} />
        <Tooltip
          content={
            <ChartTooltip timezone={site?.timezone} inverter={inverter} />
          }
        />

        <Bar
          key={`bar-${inverter.inverter_id}`}
          dataKey={inverter.inverter_id}
          fill={theme.palette.veregy_colors.orange}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

InverterChart.propTypes = {
  site: PropTypes.object,
  inverter: PropTypes.object,
};

export default InverterChart;
