import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { navigate } from '../../../../store/pages';
import { openDialog } from '../../../../store/dialogs';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import SiteProfileChart from '../../../../components/charts/preview/SiteProfileChart';
import SitePerformance from './SitePerformance';

import CustomLink from '../../../../components/CustomLink';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';

function SiteCard(props) {
  const { siteId, expectationType } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const site = useSite(siteId);
  const { alarms, loggers } = useSiteDevices(siteId);

  const active = (() => {
    let active = true;
    loggers.forEach((logger) => {
      if (!logger.active) active = false;
    });
    return active;
  })();

  const handleSiteClick = () => {
    dispatch(
      navigate({
        page: 'site',
        id: site.site_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'site',
        mode: 'edit',
        id: site.site_id,
      })
    );
  };

  const handleClick = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: siteId,
        tab: 'analysis',
        view: view,
      })
    );
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Box
      sx={{
        px: 1,
        pt: 1,
        pb: 0.5,
        backgroundColor: 'background.paper',
        boxShadow: theme.shadows[4],
        borderRadius: 1,
        width: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AlarmPreviewLink
          alarms={activeAlarms}
          deviceName={site.name}
          handleClick={() => handleSiteClick()}
        />
        <IconButton onClick={() => handleIconClick()}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      </Box>
      {active ? (
        <Grid container padding={0}>
          <Grid item xs={12} md={8}>
            <SiteProfileChart siteId={site.site_id} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomLink handleClick={() => handleClick('performance')}>
              {(expectationType === 'modeled'
                ? 'Modeled'
                : 'Weather Corrected') + ' Performance'}
            </CustomLink>
            <SitePerformance
              siteId={site.site_id}
              expectationType={expectationType}
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ my: 4, width: '100%' }}>
          <Typography align='center' color='text.secondary'>
            Data Logger is inactive
          </Typography>
        </Box>
      )}
    </Box>
  );
}

SiteCard.propTypes = {
  siteId: PropTypes.string.isRequired,
  expectationType: PropTypes.string.isRequired,
};

export default SiteCard;
