import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { meters as initialState } from '../initialState';

import { getMeters, refreshMeters, putMeter } from './_meters';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'meters',
  initialState,
  reducers: {
    setMeters: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getMeters, refreshMeters, putMeter]);
  },
});

// Extract each action creator by name
const { setMeters } = actions;

// Export the reducer, either as a default or named export
export { getMeters, refreshMeters, putMeter, setMeters };
export default reducer;
