import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { prepareSensorTimeseriesData } from '../../../../helpers/chart-data';
import { navigate } from '../../../../store/pages';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function SensorChart({ site, sensor, hideAxisLabels = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [range, setRange] = useState({});
  const [chartData, setChartData] = useState([]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      let start = dayjs
        .utc()
        .startOf('day')
        .subtract(site.timezone.offset, 'hour')
        .subtract(1, 'day');
      let end = dayjs.utc().endOf('day').subtract(site.timezone.offset, 'hour');

      setRange({ start, end });
    }
  }, [site]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(sensor) && range.start && range.end) {
      setChartData(
        prepareSensorTimeseriesData(
          {
            sensor_id: sensor.sensor_id,
            records: sensor.timeseries,
          },
          range
        ),
        [sensor],
        site?.timezone?.offset
      );
    }
  }, [sensor, range, site?.timezone?.offset]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'sensor',
        id: sensor.sensor_id,
        tab: 'analysis',
      })
    );
  };

  const height = isMobile ? 150 : 175;
  if (chartData.length === 0)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  const startRef = range?.start?.add(3, 'hour');
  const endRef = range?.start?.add(27, 'hour');
  return (
    <ResponsiveContainer
      key={`chart-${sensor.sensor_id}`}
      width='100%'
      height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 0,
        }}>
        <YAxis
          mirror
          yAxisId='temp'
          orientation='right'
          domain={[
            (dataMin) => Math.min(0, dataMin),
            (dataMax) => Math.ceil(dataMax / 10) * 10,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' °C';
          }}
          hide={hideAxisLabels}
        />
        <YAxis
          mirror
          yAxisId='radiation'
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          width={100}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2';
          }}
          hide={hideAxisLabels}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[range?.start?.unix(), range?.end?.unix()]}
          hide
        />
        <ReferenceLine
          yAxisId='radiation'
          x={startRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {startRef.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          yAxisId='radiation'
          x={endRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {endRef.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip content={<ChartTooltip timezone={site.timezone} />} />
        <Area
          yAxisId='radiation'
          key={`radiation-area-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:Radiation`}
          stroke={theme.palette.veregy_colors.darkYellow}
          fill={theme.palette.veregy_colors.darkYellow}
          fillOpacity={0.7}
        />
        <Line
          yAxisId='temp'
          dot={false}
          key={`temp-line-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:PanelTemp`}
          stroke={theme.palette.veregy_colors.purple}
          connectNulls
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

SensorChart.propTypes = {
  site: PropTypes.object,
  sensor: PropTypes.object,
  hideAxisLabels: PropTypes.bool,
};

export default SensorChart;
