import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import DevicesGrid from './DevicesGrid';
import LoggerInfo from './LoggerInfo';
import LoggerProduction from './LoggerProduction';
import ROLES from '../../../../constants/roles';
import LiveData from './LiveData';

const DEVICES = 'devices';
const ALARMS = 'alarms';
const LIVE_DATA = 'live-data';
const VIEWS = [DEVICES, ALARMS];

function LoggerDashboard() {
  const { id, view } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const site = useSite(logger.site_id);
  const { meters, inverters, alarms, loading } = useLoggerDevices(id);
  const isOrgAdmin = useVerifyOrgRole(logger?.org_id, ROLES.ADMIN.value);

  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(DEVICES);

  useEffect(() => {
    if (isOrgAdmin) {
      setViews([...VIEWS, LIVE_DATA]);
    } else {
      setViews(VIEWS);
    }
  }, [isOrgAdmin]);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(DEVICES);
    }
  }, [view]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      mt='0 !important'
      px={1}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} md={4}>
        <LoggerInfo logger={logger} loading={loading} />
      </Grid>
      <Grid item xs={12} md={4}>
        <CurrentGeneration
          loggers={[logger]}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LoggerProduction meters={meters} />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      {selectedView === DEVICES && <DevicesGrid site={site} logger={logger} />}
      {selectedView === ALARMS && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={logger} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LIVE_DATA && <LiveData />}
    </Grid>
  );
}

export default LoggerDashboard;
