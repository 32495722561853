import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';

const BottomContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '4px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.grey,
}));

function SensorInfo(props) {
  const { sensor } = props;
  const tilt = get(sensor, 'tilt');
  const azimuth = get(sensor, 'azimuth');
  const orientation = get(sensor, 'orientation', 'undefined');

  return (
    <Card raised sx={{ pt: 1 }}>
      <Stack spacing={1} direction='row' sx={{ px: 2 }}>
        <TextField
          id='orientation'
          label='Orientation'
          value={orientation}
          InputProps={{ readOnly: true, disableUnderline: true }}
          variant='standard'
        />
        <TextField
          id='tilt'
          label='Tilt'
          value={tilt ? tilt + ' °' : 'undefiend'}
          InputProps={{ readOnly: true, disableUnderline: true }}
          variant='standard'
        />
        <TextField
          id='azimuth'
          label='Azimuth'
          value={azimuth ? azimuth + ' °' : 'undefined'}
          InputProps={{ readOnly: true, disableUnderline: true }}
          variant='standard'
        />
      </Stack>
      <BottomContent>
        <Typography align='left' variant='h5' sx={{ ml: 2 }}>
          {sensor?.name}
        </Typography>
        {isEmpty(sensor) ? <WidgetLoader /> : null}
      </BottomContent>
      <BottomPanel />
    </Card>
  );
}

SensorInfo.propTypes = {
  sensor: PropTypes.object,
};

export default SensorInfo;
