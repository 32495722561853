import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import {
  calculateTotals,
  prepareEstimatedProductionTimeseriesData,
} from '../../../../helpers/chart-data';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import EstimatedChart from '.';
import Toolbar from './Toolbar';
import { RANGES } from '../../selectors/SelectRange';
import { TIMEZONES } from '../../selectors/SelectTimezone';
import WebAPIClient, { errorResponseToastr } from '../../../../api';

function EstimatedChartContainer(props) {
  const { defaultTimezone, site } = props;
  const {
    meters,
    inverters,
    sensors: siteSensors,
  } = useSiteDevices(site.site_id);

  const [sensors, setSensors] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState(RANGES[1]);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [inverterEfficiency, setInverterEfficiency] = useState(0.96);
  const [systemLosses, setSystemLosses] = useState(0.045);
  const [tempCoefficient, setTempCoefficient] = useState(-0.004);
  const [totals, setTotals] = useState({
    actual: 0,
    estimated: 0,
    irradiance: 0,
  });

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // update range, after timezone updates
  useEffect(() => {
    if (timezone?.offset) {
      setRange((r) => {
        if (r.key === 6) {
          return {
            ...r,
            start: r.func(r.start, 'start', timezone.offset),
            end: r.func(r.end, 'end', timezone.offset),
            offset: timezone.offset,
          };
        } else {
          return { ...r, ...r.func(timezone.offset), offset: timezone.offset };
        }
      });
    }
  }, [timezone.offset]);

  // setSensors
  useEffect(() => {
    let _sensors = map(siteSensors, (sensor) => {
      let [DCSize, ACSize] = reduce(
        inverters,
        (acc, inverter) => {
          if (inverter.sensor_id === sensor.sensor_id) {
            let inverterDCSize = get(inverter, 'dc_size', 0);
            let inverterACSize = get(inverter, 'ac_size', 0);
            return [acc[0] + inverterDCSize, acc[1] + inverterACSize];
          } else return acc;
        },
        [0, 0]
      );
      return { ...sensor, DCSize, ACSize };
    });

    setSensors(_sensors);
  }, [inverters, siteSensors]);

  useEffect(() => {
    if (!loading && sensors.length > 0 && range?.start && range?.end) {
      setChartData(
        prepareEstimatedProductionTimeseriesData(
          rawData,
          range,
          sensors,
          inverterEfficiency,
          systemLosses,
          tempCoefficient
        )
      );
    }
  }, [
    rawData,
    range,
    sensors,
    inverterEfficiency,
    systemLosses,
    tempCoefficient,
    loading,
  ]);

  // set default chart data
  useEffect(() => {
    if (
      !loading &&
      Object.keys(rawData).length === 0 &&
      sensors.length > 0 &&
      range?.start &&
      range?.end &&
      range?.offset === timezone.offset
    ) {
      fetchRawData(range);
    }
    // eslint-disable-next-line
  }, [loading, meters, sensors, range, rawData, timezone.offset]);

  const fetchRawData = (_range = range) => {
    setLoading(true);
    Promise.all(
      concat(
        map(meters, async (meter) => {
          return {
            meter_id: meter.meter_id,
            records: await new WebAPIClient().GET(
              `/resource/timestream/${
                meter.org_id
              }/${_range.start.valueOf()}/${_range.end.valueOf()}`,
              {
                deviceId: meter.meter_id,
              }
            ),
          };
        }),
        map(sensors, async (sensor) => {
          return {
            sensor_id: sensor.sensor_id,
            records: await new WebAPIClient().GET(
              `/resource/timestream/${
                sensor.org_id
              }/${_range.start.valueOf()}/${_range.end.valueOf()}`,
              {
                deviceId: sensor.sensor_id,
              }
            ),
          };
        })
      )
    )
      .then((payload) => {
        setRawData(payload);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isEmpty(chartData)) {
      setTotals(calculateTotals(chartData));
    }
  }, [chartData]);

  const handleSelectRange = (newRange, fetchData) => {
    setRange(newRange);
    if (fetchData) {
      fetchRawData(newRange);
    }
  };

  return (
    <>
      <Toolbar
        site={site}
        range={range}
        selectRange={handleSelectRange}
        timezone={timezone}
        selectTimezone={setTimezone}
        inverterEfficiency={inverterEfficiency}
        setInverterEfficiency={setInverterEfficiency}
        systemLosses={systemLosses}
        setSystemLosses={setSystemLosses}
        tempCoefficient={tempCoefficient}
        setTempCoefficient={setTempCoefficient}
        chartData={chartData}
        fetchRawData={fetchRawData}
      />

      <EstimatedChart
        loading={loading}
        range={range}
        chartData={chartData}
        totals={totals}
        timezone={timezone}
      />
    </>
  );
}

EstimatedChartContainer.propTypes = {
  sites: PropTypes.array,
  meters: PropTypes.array,
};

export default EstimatedChartContainer;
