import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import { refreshMeters } from '../meters';

const useMeters = (id) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (id && organizations.length && sites.length && meters.length) {
      if (id.startsWith('org:')) {
        setDevice(find(organizations, { org_id: id }));
      } else if (id.startsWith('site:')) {
        setDevice(find(sites, { site_id: id }));
      } else if (id.startsWith('logger:')) {
        setDevice(find(loggers, { logger_id: id }));
      } else if (id.startsWith('meter:')) {
        setDevice(find(meters, { meter_id: id }));
      }
    }
  }, [id, organizations, sites, loggers, meters]);

  useEffect(() => {
    const refreshMetersCheck = (_meters) => {
      dispatch(refreshMeters(map(_meters, (meter) => meter.meter_id)));
    };

    let _meters = [];
    if (device?.type_ === 'organization') {
      if (!device.is_portfolio) {
        _meters = filter(meters, { org_id: device.org_id });
      } else {
        _meters = meters;
      }
    } else if (device?.type_ === 'site') {
      _meters = filter(meters, { site_id: device.site_id });
    } else if (device?.type_ === 'logger') {
      _meters = filter(meters, { logger_id: device.logger_id });
    } else if (device?.type_ === 'meter') {
      _meters = [device];
    }

    if (_meters.length > 0) {
      refreshMetersCheck(_meters);
      const interval = setInterval(async () => {
        refreshMetersCheck(_meters);
      }, 300000); // 5 min
      return () => clearInterval(interval);
    }
    /* eslint-disable-next-line */
  }, [device]);
};

export default useMeters;
