import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { inverters as initialState } from '../initialState';

import { getInverters, putInverter, refreshInverters } from './_inverters';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'inverters',
  initialState,
  reducers: {
    setInverters: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getInverters, putInverter, refreshInverters]);
  },
});

// Extract each action creator by name
const { setInverters } = actions;

// Export the reducer, either as a default or named export
export { getInverters, putInverter, setInverters, refreshInverters };
export default reducer;
