const details = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const progress = {
  id: null,
  progress: 0,
  expectedTime: 10000,
  visible: false,
  referredFrom: null,
  ...details,
};

export const nodes = {
  data: [],
  ...details,
};

export const pages = {
  loaderOpen: false,
  portfolio: { id: '', tab: '', view: '', expectationType: 'modeled' },
  organization: { id: '', tab: '', view: '', expectationType: 'modeled' },
  site: { id: '', tab: '', view: '', expectationType: 'modeled' },
  logger: { id: '', tab: '', view: '' },
  meter: { id: '', tab: '', view: '' },
  inverter: { id: '', tab: '', view: '' },
  sensor: { id: '', tab: '', view: '' },
  admin: { tab: '', view: '' },
};

export const dialogs = {
  user: { id: '', mode: '' },
  organization: { id: '', mode: '' },
  membership: { id: '', mode: '' },
  portfolio_membership: { id: '', mode: '' },
  site: { id: '', orgId: '', mode: '' },
  expectations: { id: '' },
  meter: { id: '', mode: '' },
  logger: { id: '', mode: '' },
  inverter: { id: '', mode: '' },
  sensor: { id: '', mode: '' },
  alarm: { id: '', mode: '' },
  report: { id: '', mode: '' },
  generateReport: { reportId: '' },
  trigger_device: {
    active: false,
    device_id: '',
    name: '',
    subscribed: false,
    trigger_id: '',
    type: '',
  },
  trigger: { id: '' },
  info: { title: '', content: '', mode: '' },
  confirm: { title: '', message: '', onConfirm: null },
  subscribers: { triggerDevice: {} },
  irregularInverterDataAlarm: {
    allActive: [],
    trigger_device_id: '',
  },
  emailedUsers: { alarm: {} },
  generator: { id: '', mode: '' },
  generator_meter: { id: '', mode: '' },
};

export const user = {
  item: null,
  memberships: [],
  subscriptions: [],
  ...details,
};

export const memberships = {
  data: [],
  ...details,
};

export const admin = {
  users: [],
  memberships: [],
  subscribedUsers: [],
  ...details,
};

export const organizations = {
  data: [],
  portfolioMemberships: [],
  ...details,
};

export const sites = {
  data: [],
  ...details,
};

export const loggers = {
  data: [],
  loaded: false,
  ...details,
};

export const inverters = {
  data: [],
  ...details,
};

export const sensors = {
  data: [],
  ...details,
};

export const meters = {
  data: [],
  ...details,
};

export const licenses = {
  data: [],
  ...details,
};

export const alarms = {
  data: [],
  triggers: [],
  triggerDevices: [],
  ...details,
};

export const images = {
  resource_id: '',
  data: [],
  presignedUrls: {},
  ...details,
};

export const reports = {
  data: [],
  userReports: [],
  templates: [],
  ...details,
};

export const generators = {
  data: [],
  generatorMeters: [],
  orgId: '',
  ...details,
};

export const kiosk = {
  ...details,
  lastUpdate: null,
  validLicense: null,
  resource: {
    theme: {},
  },
  sites: [],
  meters: [],
  inverters: [],
  current: { rawData: [], lastUpdate: null },
  lastMonth: { rawData: [] },
  images: {
    caseStudy: {},
    data: [],
    selectedIdx: 0,
  },
  settings: {
    selectedEnergy: 0,
    costSavings: '',
    slideHeight: 0,
  },
};
