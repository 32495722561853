import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import remove from 'lodash/remove';
import toArray from 'lodash/toArray';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { getLatestInterval } from '../../helpers/date';

const getSensors = createAsyncThunk(
  'sensors/getSensors',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sensors;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const sensors = await new WebAPIClient().GET('/resource/sensors');
    return { data: sensors };
  }
);

const refreshSensors = createAsyncThunk(
  'sensors/refreshSensors',
  async (sensorIds, { getState, dispatch, requestId }) => {
    const { data: sensors, loading, currentRequestId } = getState().sensors;
    const { data: loggers } = getState().loggers;
    const { data: meters } = getState().meters;
    let allSensors = cloneDeep(sensors);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let _sensors = filter(
        map(toArray(sensorIds), (sensorId) => {
          return find(sensors, { sensor_id: sensorId });
        }),
        (sensor) => {
          const meter = find(meters, { meter_id: sensor.meter_id });
          const logger = find(loggers, { logger_id: meter.logger_id });
          if (isNull(sensor) || !logger.active) return false;

          let lastRefresh = get(sensor, 'lastRefresh');
          return !lastRefresh || lastRefresh.isBefore(latestInterval);
        }
      );

      // refresh sensors
      if (_sensors.length > 0) {
        console.info(
          `REFRESH :: ${_sensors.length} SENSORS ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        dispatch(showLoading());
        let resolvedSensors = await Promise.all(
          map(_sensors, async (sensor) => {
            const _sensor = await new WebAPIClient().GET(
              `/resource/refresh_sensor/${sensor.org_id}/${sensor.sensor_id}`
            );

            return {
              ..._sensor,
              lastRefresh: dayjs(),
            };
          })
        ).then((updatedSensors) => {
          each(updatedSensors, (updatedSensor) => {
            remove(allSensors, {
              sensor_id: get(updatedSensor, 'sensor_id'),
            });
          });

          return concat(allSensors, updatedSensors);
        });

        return { data: resolvedSensors };
      }
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSensor = createAsyncThunk(
  'sensors/putSensor',
  async (sensor, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: sensors } = getState().sensors;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedSensor = await new WebAPIClient().PUT(
        `/resource/sensors/${sensor.org_id}/${sensor.sensor_id}`,
        sensor
      );

      let _sensors = cloneDeep(sensors);
      remove(_sensors, { sensor_id: get(updatedSensor, 'sensor_id') });
      _sensors = concat(_sensors, updatedSensor);

      toastr.success('Sensor updated');
      return { data: _sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { refreshSensors, getSensors, putSensor };
