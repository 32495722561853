import { createSlice } from '@reduxjs/toolkit';

import { buildAsyncReducers } from '../thunkTemplate';
import { alarms as initialState } from '../initialState';
import {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
  updateTriggerDevices,
} from './_alarms';

const { reducer } = createSlice({
  name: 'alarms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getAlarms,
      getAllAlarms,
      acknowledgeAlarm,
      closeAlarm,
      deleteAlarm,
      createTriggerDevices,
      deleteTriggerDevices,
      updateTriggerDevices,
    ]);
  },
});

export {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
  updateTriggerDevices,
};
export default reducer;
