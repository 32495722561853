import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import dayjs from 'dayjs';

import forEach from 'lodash/forEach';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { buildAsyncReducers } from '../thunkTemplate';
import { kiosk } from '../initialState';

const getKioskData = createAsyncThunk(
  'kiosk/getKioskData',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().kiosk;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';

      let resource = null;
      let sites = null;

      const payload = await new WebAPIClient().GET(
        `/kiosk/${props.type}/${props.kioskCode}`
      );
      let images = await new WebAPIClient().GET(
        `/kiosk/images/${props.type}/${props.kioskCode}`
      );

      const caseStudy = head(
        remove(images, (image) => {
          return image.filename.startsWith('case-study.');
        })
      );
      const logo = head(
        remove(images, (image) => {
          return image.filename.startsWith('logo.');
        })
      );

      if (props.type === 'organization') {
        resource = get(payload, 'organization', {});
        sites = sortBy(get(payload, 'sites', []), 'name');
      } else if (props.type === 'site') {
        const site = get(payload, 'site', {});
        resource = site;
        sites = [site];
      }

      return {
        validLicense: true,
        resource,
        sites,
        meters: sortBy(get(payload, 'meters', []), 'name'),
        inverters: get(payload, 'inverters', []),
        images: {
          caseStudy,
          logo,
          data: images,
          selectedIdx: 0,
        },
      };
    } catch (err) {
      errorResponseToastr(err);
      return {
        validLicense: false,
      };
    } finally {
      dispatch(hideLoading());
    }
  }
);

const refreshKioskMeters = createAsyncThunk(
  'kiosk/refreshKioskMeters',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().kiosk;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';

      const meters = await Promise.all(
        props.devices.map((device) => {
          const deviceId = get(device, `${device.type_}_id`);
          return new WebAPIClient().GET(
            `/kiosk/${props.type}/${props.kioskCode}/${deviceId}`
          );
        })
      );
      return { meters, lastUpdate: dayjs() };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getLastMonthData = createAsyncThunk(
  'kiosk/getLastMonthData',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().kiosk;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';
      let month = dayjs().subtract(1, 'month').startOf('month');

      let _rawData = await Promise.all(
        map(props.meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${props.type}/${props.kioskCode}/${
              meter.meter_id
            }/${month.format('YYYY-MM')}`
          )
        )
      );

      return { lastMonth: { rawData: _rawData } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getKioskChartData = createAsyncThunk(
  'kiosk/getKioskChartData',
  async (props, { dispatch, getState, requestId }) => {
    const { meters, currentRequestId, loading } = getState().kiosk;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';

      let _rawData = [];
      if (props.range.key === 0) {
        forEach(meters, (meter) => {
          _rawData.push({
            meter_id: meter.meter_id,
            records: meter.timeseries,
          });
        });
      } else {
        _rawData = await Promise.all(
          map(meters, (meter) =>
            new WebAPIClient().GET(
              `/kiosk/${props.type}/${props.kioskCode}/${
                meter.meter_id
              }/${Math.round(props.range.start / 1000)}/${Math.round(
                props.range.end / 1000
              )}`
            )
          )
        );
      }

      return { current: { rawData: _rawData, lastUpdate: dayjs() } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const { actions, reducer } = createSlice({
  name: 'kiosk',
  initialState: kiosk,
  reducers: {
    setImageIndex: (state, { payload }) => {
      let idx = null;

      if (state.images.selectedIdx === payload - 1) {
        idx = 0;
      } else {
        idx = state.images.selectedIdx + 1;
      }

      return {
        ...state,
        images: {
          ...state.images,
          selectedIdx: idx,
        },
      };
    },
    setSelectedEnergy: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedEnergy: payload,
        },
      };
    },
    setCostSavings: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          costSavings: payload,
        },
      };
    },
    setSlideHeight: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          slideHeight: payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getKioskData,
      refreshKioskMeters,
      getLastMonthData,
      getKioskChartData,
    ]);
  },
});

const { setImageIndex, setSelectedEnergy, setCostSavings, setSlideHeight } =
  actions;

export {
  getKioskData,
  refreshKioskMeters,
  getLastMonthData,
  getKioskChartData,
  setImageIndex,
  setSelectedEnergy,
  setCostSavings,
  setSlideHeight,
};
export default reducer;
