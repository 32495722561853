import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import remove from 'lodash/remove';
import toArray from 'lodash/toArray';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { getLatestInterval } from '../../helpers/date';

const getMeters = createAsyncThunk(
  'meters/getMeters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().meters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const meters = await new WebAPIClient().GET('/resource/meters');
    return { data: meters };
  }
);

const refreshMeters = createAsyncThunk(
  'meters/refreshMeters',
  async (meterIds, { getState, dispatch, requestId }) => {
    const { data: meters, loading, currentRequestId } = getState().meters;
    const { data: loggers } = getState().loggers;
    let allMeters = cloneDeep(meters);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let _meters = filter(
        map(toArray(meterIds), (meterId) => {
          return find(meters, { meter_id: meterId });
        }),
        (meter) => {
          const logger = find(loggers, { logger_id: meter.logger_id });
          if (isNull(meter) || !logger.active) return false;

          let lastRefresh = get(meter, 'lastRefresh');
          return !lastRefresh || lastRefresh.isBefore(latestInterval);
        }
      );

      // refresh meters
      if (_meters.length > 0) {
        dispatch(showLoading());
        console.info(
          `REFRESH :: ${_meters.length} METERS ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        let resolvedMeters = await Promise.all(
          map(_meters, async (meter) => {
            const _meter = await new WebAPIClient().GET(
              `/resource/refresh_meter/${meter.org_id}/${meter.meter_id}`
            );
            return { ..._meter, lastRefresh: dayjs() };
          })
        ).then((updatedMeters) => {
          each(updatedMeters, (updatedMeter) => {
            remove(allMeters, {
              meter_id: get(updatedMeter, 'meter_id'),
            });
          });

          return concat(allMeters, updatedMeters);
        });
        return { data: resolvedMeters };
      }
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMeter = createAsyncThunk(
  'meters/putMeter',
  async (meter, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: meters } = getState().meters;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedMeter = await new WebAPIClient().PUT(
        `/resource/meters/${meter.org_id}/${meter.meter_id}`,
        meter
      );

      let _meters = cloneDeep(meters);
      remove(_meters, { meter_id: get(updatedMeter, 'meter_id') });

      toastr.success('Meter updated');
      return { data: concat(_meters, updatedMeter) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMeters, putMeter, refreshMeters };
