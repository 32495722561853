import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import { refreshLoggers } from '../loggers';

const useLoggers = (id) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);
  const { data: loggers } = useSelector((state) => state.loggers);
  const [firstLoad, setFirstLoad] = useState(false);

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (id && organizations.length && sites.length && loggers.length) {
      if (id.startsWith('org:')) {
        setDevice(find(organizations, { org_id: id }));
      } else if (id.startsWith('site:')) {
        setDevice(find(sites, { site_id: id }));
      } else if (id.startsWith('logger:')) {
        setDevice(find(loggers, { logger_id: id }));
      }
    }
  }, [id, organizations, sites, loggers]);

  useEffect(() => {
    const refreshLoggersCheck = (loggers) => {
      if (!firstLoad) {
        dispatch(refreshLoggers(map(loggers, (logger) => logger.logger_id)));
        setFirstLoad(true);
      }
    };

    let _loggers = [];
    if (device?.type_ === 'organization') {
      if (!device.is_portfolio) {
        _loggers = filter(loggers, { org_id: device.org_id });
      } else {
        _loggers = loggers;
      }
    } else if (device?.type_ === 'site') {
      _loggers = filter(loggers, { site_id: device.site_id });
    } else if (device?.type_ === 'logger') {
      _loggers = [device];
    }

    if (_loggers.length > 0) {
      refreshLoggersCheck(_loggers);
      const interval = setInterval(async () => {
        refreshLoggersCheck(_loggers);
      }, 300000); // 5 min
      return () => clearInterval(interval);
    }
    /* eslint-disable-next-line */
  }, [device]);
};

export default useLoggers;
