import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlarmExclamation as faAlarmExclamationLight,
  faArrowToBottom as faArrowToBottomLight,
  faArrowToLeft as faArrowToLeftLight,
  faBars as faBarsLight,
  faBellOn as faBellOnLight,
  faBellPlus as faBellPlusLight,
  faBellSlash as faBellSlashLight,
  faBolt as faBoltLight,
  faBroadcastTower as faBroadcastTowerLight,
  faBuilding as faBuildingLight,
  faBuildings as faBuildingsLight,
  faCaretDown as faCaretDownLight,
  faCaretRight as faCaretRightLight,
  faCars as faCarsLight,
  faCheckSquare as faCheckSquareLight,
  faChevronDown as faChevronDownLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faChevronUp as faChevronUpLight,
  faClipboard as faClipboardLight,
  faClock as faClockLight,
  faCloudDownload as faCloudDownloadLight,
  faCog as faCogLight,
  faCopy as faCopyLight,
  faDoNotEnter as faDoNotEnterLight,
  faEdit as faEditLight,
  faEllipsisV as faEllipsisVLight,
  faExchange as faExchangeLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faFolder as faFolderLight,
  faHome as faHomeLight,
  faInfoCircle as faInfoCircleLight,
  faLightbulb as faLightbulbLight,
  faList as faListLight,
  faLongArrowRight as faLongArrowRightLight,
  faMinusCircle as faMinusCircleLight,
  faMinusSquare as faMinusSquareLight,
  faPause as faPauseLight,
  faPlay as faPlayLight,
  faPlusCircle as faPlusCircleLight,
  faQuestionCircle as faQuestionCircleLight,
  faRadar as faRadarLight,
  faRouter as faRouterLight,
  faSackDollar as faSackDollarLight,
  faSave as faSaveLight,
  faSearch as faSearchLight,
  faShieldAlt as faShieldAltLight,
  faSignal1 as faSignal1Light,
  faSignal2 as faSignal2Light,
  faSignal3 as faSignal3Light,
  faSignal4 as faSignal4Light,
  faSignOut as faSignOutLight,
  faSquare as faSquareLight,
  faSync as faSyncLight,
  faThunderstormSun as faThunderstormSunLight,
  faThermometerHalf as faThermometerHalfLight,
  faTimes as faTimesLight,
  faTrash as faTrashLight,
  faTrees as faTreesLight,
  faUndo as faUndoLight,
  faUpload as faUploadLight,
  faUserCircle as faUserCircleLight,
  faUsers as faUsersLight,
  faUserCrown as faUserCrownLight,
  faXmark as faXmarkLight,
} from '@fortawesome/pro-light-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default function initIcons() {
  library.add(
    faAlarmExclamationLight,
    faArrowToBottomLight,
    faArrowToLeftLight,
    faBarsLight,
    faBellOnLight,
    faBellPlusLight,
    faBellSlashLight,
    faBoltLight,
    faBroadcastTowerLight,
    faBuildingLight,
    faBuildingsLight,
    faCaretDownLight,
    faCaretRightLight,
    faCarsLight,
    faCheckSquareLight,
    faChevronDownLight,
    faChevronLeftLight,
    faChevronRightLight,
    faChevronUpLight,
    faClipboardLight,
    faClockLight,
    faCloudDownloadLight,
    faCogLight,
    faCopyLight,
    faDoNotEnterLight,
    faEditLight,
    faEllipsisVLight,
    faEyeLight,
    faEyeSlashLight,
    faExchangeLight,
    faExclamationTriangleLight,
    faFolderLight,
    faGithub,
    faHomeLight,
    faInfoCircleLight,
    faLightbulbLight,
    faListLight,
    faLongArrowRightLight,
    faMinusCircleLight,
    faMinusSquareLight,
    faPauseLight,
    faPlayLight,
    faPlusCircleLight,
    faQuestionCircleLight,
    faRadarLight,
    faRouterLight,
    faSackDollarLight,
    faSaveLight,
    faSearchLight,
    faShieldAltLight,
    faSignal1Light,
    faSignal2Light,
    faSignal3Light,
    faSignal4Light,
    faSignOutLight,
    faSquareLight,
    faSyncLight,
    faThermometerHalfLight,
    faThunderstormSunLight,
    faTimesLight,
    faTrashLight,
    faTreesLight,
    faUndoLight,
    faUploadLight,
    faUserCircleLight,
    faUsersLight,
    faUserCrownLight,
    faXmarkLight
  );
}
