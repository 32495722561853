import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import _range from 'lodash/range';
import dayjs from 'dayjs';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import { addEstimatedProductionToChartData } from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function MeterProfileChart({ meterId, hideAxisLabels = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const meter = useMeter(meterId);
  const site = useSite(meter.site_id);

  const [range, setRange] = useState({});
  const [chartData, setChartData] = useState([]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      let start = dayjs
        .utc()
        .startOf('day')
        .subtract(site.timezone.offset, 'hour')
        .subtract(1, 'day');
      let end = dayjs.utc().endOf('day').subtract(site.timezone.offset, 'hour');

      setRange({ start, end });
    }
  }, [site]);

  useEffect(() => {
    if (!isEmpty(meter) && range.start && range.end) {
      let _data = map(
        _range(range?.start?.unix(), range?.end?.unix(), 900),
        (timestamp) => ({
          timestamp,
        })
      );

      each(meter.timeseries, (datapoint) => {
        let idx = findIndex(_data, { timestamp: datapoint.timestamp });
        if (idx >= 0) {
          _data[idx] = {
            ..._data[idx],
            [meter.meter_id]: datapoint.value,
          };
        }
      });

      setChartData(
        addEstimatedProductionToChartData(
          _data,
          [meter],
          site?.timezone?.offset
        )
      );
    }
  }, [site?.timezone?.offset, meter, range]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meterId,
        tab: 'analysis',
      })
    );
  };

  const height = isMobile ? 150 : 175;
  if (chartData.length === 0)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  const startRef = range?.start?.add(3, 'hour');
  const endRef = range?.start?.add(27, 'hour');
  return (
    <ResponsiveContainer width='100%' height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis
          hide={hideAxisLabels}
          mirror
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[range?.start?.unix(), range?.end?.unix()]}
          hide
        />
        <ReferenceLine
          x={startRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>{startRef.format('MMM Do')}</Label>
          }
        />
        <ReferenceLine
          x={endRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>{endRef.format('MMM Do')}</Label>
          }
        />
        <Tooltip
          content={<ChartTooltip timezone={site.timezone} meter={meter} />}
        />
        <Area
          key={`area-${meter.meter_id}`}
          type='monotone'
          dataKey={meter.meter_id}
          fill={theme.palette.veregy_colors.blue}
        />
        <Bar
          key={`bar-${meter.meter_id}`}
          dataKey={`${meter.meter_id}:estimated`}
          fill={theme.palette.veregy_colors.lightgrey}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

MeterProfileChart.propTypes = {
  meterId: PropTypes.string,
};

export default MeterProfileChart;
