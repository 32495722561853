import React from 'react';
import get from 'lodash/get';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../helpers/display-energy';

export const CurrentGenerationTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        const generation = get(value, 'generation');
        return (
          <Typography align='right'>{`${displaykW(generation)}`}</Typography>
        );
      }}
      {...props}
    />
  );
};
