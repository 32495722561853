import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { default as _range } from 'lodash/range';
import remove from 'lodash/remove';
import set from 'lodash/set';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { generateCsvString } from '../../../../helpers/csv-data';
import SelectMeters from '../../selectors/SelectMeters';
import SelectSensors from '../../selectors/SelectSensors';
import SelectRange, { RANGES } from '../../selectors/SelectRange';
import SelectTimezone, { TIMEZONES } from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import WeatherStationChart from '.';
import WeatherStationChartTotals from './Totals';
import ChartHeader from '../ChartHeader';

const compileChartData = (raw, range) => {
  let rawData = cloneDeep(raw);
  return map(
    _range(range.start.unix(), range.end.unix() + 1, 900),
    (timestamp) => {
      let pointData = { timestamp };
      each(rawData, (deviceData) => {
        let deviceId = get(deviceData, 'device_id');

        let deviceDataPoints = remove(
          get(deviceData, 'records', []),
          (record) => {
            if (
              timestamp <= record.timestamp &&
              record.timestamp < timestamp + 900
            ) {
              return record;
            }
          }
        );

        if (!isEmpty(deviceDataPoints)) {
          if (deviceId.startsWith('sensor')) {
            each(deviceDataPoints, (dataPoint) => {
              let measureName = get(dataPoint, 'measure_name');
              let attrName = measureName.substring(
                0,
                indexOf(measureName, '_')
              );

              pointData[`${deviceId}:${attrName}`] = get(dataPoint, 'value');
            });
          } else if (deviceId.startsWith('meter')) {
            pointData[deviceId] = get(deviceDataPoints, '0.value');
          }
        }
      });
      return pointData;
    }
  );
};

const prepareCsvData = (data, range, meters, sensors) => {
  let headers = ['timestamp'];
  let rawData = cloneDeep(data);
  let start = range.start / 1000;
  let end = range.end / 1000;

  let timeseries = map(_range(start, end, 900), (timestamp) => {
    let values = {};
    each(rawData, (resource) => {
      let deviceId = get(resource, 'device_id');
      let records = get(resource, 'records');

      if (deviceId?.startsWith('meter:')) {
        let meter = find(meters, { meter_id: deviceId });
        let column = `${get(meter, 'name')}-kw-production`;
        let deviceDataPoints = remove(records, (record) => {
          if (record.timestamp < timestamp + 900) {
            return record;
          }
        });
        if (!isEmpty(deviceDataPoints)) {
          set(values, column, get(deviceDataPoints, '0.value', 0));
          if (!headers.includes(column)) {
            headers.push(column);
          }
        }
      } else if (deviceId?.startsWith('sensor:')) {
        let sensor = find(sensors, { sensor_id: deviceId });
        let meter = find(meters, { meter_id: get(sensor, 'meter_id') });

        let radColumn = `${get(meter, 'name')}-sensor-${get(
          sensor,
          'parent_index'
        )}-radiation`;
        let tempColumn = `${get(meter, 'name')}-sensor-${get(
          sensor,
          'parent_index'
        )}-temp`;

        let deviceDataPoints = remove(records, (record) => {
          if (record.timestamp < timestamp + 900) {
            return record;
          }
        });
        if (!isEmpty(deviceDataPoints)) {
          let panelTemp = get(
            find(deviceDataPoints, (point) =>
              point.measure_name.startsWith('PanelTemp')
            ),
            'value',
            null
          );
          let radiation = get(
            find(deviceDataPoints, (point) =>
              point.measure_name.startsWith('Radiation')
            ),
            'value',
            null
          );
          set(values, radColumn, radiation);
          set(values, tempColumn, panelTemp);
          if (!headers.includes(radColumn)) {
            headers.push(radColumn);
          }
          if (!headers.includes(tempColumn)) {
            headers.push(tempColumn);
          }
        }
      }
    });
    return {
      timestamp: dayjs(timestamp * 1000).format(),
      ...values,
    };
  });
  remove(timeseries, (datapoint) => {
    return Object.keys(datapoint).length <= 1;
  });
  return generateCsvString(headers, timeseries);
};

function WeatherStationChartContainer(props) {
  const { defaultTimezone, meters, sensors } = props;

  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [range, setRange] = useState(RANGES[1]);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);

  // set default selected sensors
  useEffect(() => {
    const defaultSensor = head(sensors);
    setSelectedSensors([defaultSensor]);
  }, [sensors]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // update range, after timezone updates
  useEffect(() => {
    if (timezone?.offset) {
      setRange((r) => {
        if (r.key === 6) {
          return {
            ...r,
            start: r.func(r.start, 'start', timezone.offset),
            end: r.func(r.end, 'end', timezone.offset),
            offset: timezone.offset,
          };
        } else {
          return { ...r, ...r.func(timezone.offset), offset: timezone.offset };
        }
      });
    }
  }, [timezone.offset]);

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData, range));
    }
  }, [rawData, range, loading]);

  // set default chart data
  useEffect(() => {
    if (
      !loading &&
      rawData.length === 0 &&
      range.start &&
      range.end &&
      range?.offset === timezone.offset
    ) {
      fetchRawData(range);
    }
    // eslint-disable-next-line
  }, [rawData, range, loading, timezone.offset]);

  const fetchRawData = (_range = range) => {
    setLoading(true);
    Promise.all(
      map(concat(selectedMeters, selectedSensors), async (device) => {
        const deviceId = get(device, `${device.type_}_id`);
        return {
          device_id: deviceId,
          records: await new WebAPIClient().GET(
            `/resource/timestream/${
              device.org_id
            }/${_range.start.valueOf()}/${_range.end.valueOf()}`,
            {
              deviceId,
            }
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectRange = (newRange, fetchData) => {
    setRange(newRange);
    if (fetchData) {
      fetchRawData(newRange);
    }
  };

  return (
    <>
      <ChartHeader>
        <SelectMeters
          allMeters={meters}
          selectedMeters={selectedMeters}
          setSelectedMeters={setSelectedMeters}
        />

        <SelectSensors
          allSensors={sensors}
          selectedSensors={selectedSensors}
          setSelectedSensors={setSelectedSensors}
        />

        <SelectRange
          range={range}
          setRange={handleSelectRange}
          timezone={timezone}
        />

        <SelectTimezone
          selectedTimezone={timezone}
          setSelectedTimezone={setTimezone}
        />

        <Tooltip title='fetch data' placement='top'>
          <IconButton onClick={() => fetchRawData(range)}>
            <FontAwesomeIcon icon={['fal', 'cloud-download']} />
          </IconButton>
        </Tooltip>

        <Tooltip title='download CSV' placement='top'>
          <span>
            <GenerateCSVButton
              generateCsvString={() =>
                prepareCsvData(rawData, range, selectedMeters, selectedSensors)
              }
              filename={`Weather Station (${dayjs().format(
                'ddd MMM DD YYYY'
              )})`}
            />
          </span>
        </Tooltip>
      </ChartHeader>
      <Grid item xs={12}>
        <Card raised sx={{ px: 1, py: 0.5 }}>
          <WeatherStationChart
            sensors={selectedSensors}
            meters={selectedMeters}
            chartData={chartData}
            range={range}
            timezone={timezone}
            loading={loading}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <WeatherStationChartTotals
          selectedMeters={selectedMeters}
          selectedSensors={selectedSensors}
          rawData={rawData}
        />
      </Grid>
    </>
  );
}

WeatherStationChartContainer.propTypes = {
  defaultTimezone: PropTypes.object,
  meters: PropTypes.array,
  sensors: PropTypes.array,
};

export default WeatherStationChartContainer;
