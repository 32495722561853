import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { displaykW } from '../../../../helpers/display-energy';
import { unixTimestampToString } from '../../../../helpers/date';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, meters }) {
  const meterName = (resource) => {
    const estimated = resource.dataKey.endsWith(':estimated');
    const meterId = estimated
      ? resource.dataKey.replace(':estimated', '')
      : resource.dataKey;
    const meter = find(meters, { meter_id: meterId });
    return meter?.name;
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={unixTimestampToString(Number(label), timezone?.offset)}
      getName={meterName}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
