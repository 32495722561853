import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { refreshSensors } from '../sensors';

const useSensors = (id) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);
  const sensors = useSelector((state) => state.sensors.data);

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (id && organizations.length && sites.length && meters.length) {
      if (id.startsWith('org:')) {
        setDevice(find(organizations, { org_id: id }));
      } else if (id.startsWith('site:')) {
        setDevice(find(sites, { site_id: id }));
      } else if (id.startsWith('logger:')) {
        setDevice(find(loggers, { logger_id: id }));
      } else if (id.startsWith('meter:')) {
        setDevice(find(meters, { meter_id: id }));
      } else if (id.startsWith('sensor:')) {
        setDevice(find(sensors, { sensor_id: id }));
      }
    }
  }, [id, organizations, sites, loggers, meters, sensors]);

  useEffect(() => {
    const refreshSensorsCheck = (sensors) => {
      dispatch(refreshSensors(map(sensors, (sensor) => sensor.sensor_id)));
    };

    let _sensors = [];
    if (device?.type_ === 'organization') {
      if (!device.is_portfolio) {
        _sensors = filter(sensors, { org_id: device.org_id });
      } else {
        _sensors = sensors;
      }
    } else if (device?.type_ === 'site') {
      let _siteMeters = filter(meters, { site_id: device.site_id });
      let meterIds = map(_siteMeters, (meter) => meter.meter_id);
      _sensors = filter(sensors, (sensor) =>
        includes(meterIds, sensor.meter_id)
      );
    } else if (device?.type_ === 'logger') {
      let _loggerMeters = filter(meters, { logger_id: device.logger_id });
      let meterIds = map(_loggerMeters, (meter) => meter.meter_id);
      _sensors = filter(sensors, (sensor) =>
        includes(meterIds, sensor.meter_id)
      );
    } else if (device?.type_ === 'meter') {
      _sensors = filter(sensors, { meter_id: device.meter_id });
    } else if (device?.type_ === 'sensor') {
      _sensors = [device];
    }

    if (_sensors.length > 0) {
      refreshSensorsCheck(_sensors);
      const interval = setInterval(async () => {
        refreshSensorsCheck(_sensors);
      }, 300000); // 5 min
      return () => clearInterval(interval);
    }
    /* eslint-disable-next-line */
  }, [device]);
};

export default useSensors;
